/* eslint-disable no-underscore-dangle */
import HttpClient from 'src/common/api/HttpClient';
import { QUIZ_NAMES } from 'src/common/types';
import { ApiError, UnknownError } from 'src/common/errors';
import { ConsolaLogger } from './logger';
import { BaseService } from './BaseService';

export interface AuthenticatedUserDTO {
  id: string;
}

export interface NewUserDTO {
  email: string;
  name: string;
  gender: string;
  birth_day: string;
  birth_time: string;
  birth_place: string;
  longitude: string;
  latitude: string;
  extra_data: Record<string, any>;
  acquisition_data: {
    gender: string;
    partnerGender: string;
    zodiacSign: string;
    partnerZodiacSign: string;
  };
  acquisition_source: QUIZ_NAMES;
}

export interface SoftUserDTO {
  email: string;
  acquisition_data: Record<string, any>,
  acquisition_source: QUIZ_NAMES;
}

export class AuthService extends BaseService {
  private tokenExpireAtKey = 'ASK_Nebula_exp_at';

  private authStateChangeListeners: Array<(isAuthorized: boolean) => void> = [];

  private _isAuthorized = false;

  constructor(httpClient: HttpClient, logger: ConsolaLogger) {
    super(httpClient, logger);
    this.httpClient.onUnauthorized(this.onUnauthorized.bind(this));
    this._isAuthorized = !this.isRefreshTokenExpired();
  }

  get isAuthorized(): boolean {
    return this._isAuthorized;
  }

  onAuthorizedStateChanged(listener: (isAuthorized: boolean) => void) {
    this.authStateChangeListeners.push(listener);
  }

  async signInByToken(token: string) {
    return this.httpClient.get(`/auth/authenticate-by-token/${token}`);
  }

  async signUpViaQuiz(userInfo: NewUserDTO) {
    const res: {
      access_expires_at: number;
      refresh_expires_at: number;
      user: AuthenticatedUserDTO;
    } = await this.httpClient.post('/funnel/user', userInfo);
    this.maybeChangeAuthorizedState(true);
    this.storeTokenExpiration(res.refresh_expires_at);
    return res;
  }

  async signUpSoftUser(userInfo: SoftUserDTO) {
    const res: {
      access_expires_at: number;
      refresh_expires_at: number;
      user: AuthenticatedUserDTO;
    } = await this.httpClient.post('/funnel/soft/user', userInfo);
    this.maybeChangeAuthorizedState(true);
    this.storeTokenExpiration(res.refresh_expires_at);
    return res;
  }

  async confirmEmail(token: string) {
    return this.httpClient.post('/auth/confirm-email', {
      confirmation_token: token,
    });
  }

  async createAuthenticationToken(): Promise<string> {
    const result = await this.httpClient.get<{ token: string }>('/auth/authentication-token');
    return result.token;
  }

  private onUnauthorized() {
    this.maybeChangeAuthorizedState(false);
    this.storeTokenExpiration(0);
  }

  private maybeChangeAuthorizedState(isAuthorized: boolean) {
    if (isAuthorized === this._isAuthorized) return;

    this._isAuthorized = isAuthorized;
    this.notifyAuthorizedStateChanged();
  }

  private notifyAuthorizedStateChanged() {
    this.authStateChangeListeners.forEach((listener) => {
      try {
        listener(this._isAuthorized);
      } catch (err) {
        const error = err instanceof ApiError ? err : new UnknownError(err);
        this.logger.error(error);
      }
    });
  }

  private isRefreshTokenExpired(): boolean {
    return this.getStoredTokenExpiration() < Date.now();
  }

  private getStoredTokenExpiration() {
    const expireAtRaw = localStorage.getItem(this.tokenExpireAtKey) || '0';
    const expireAt = parseFloat(expireAtRaw);
    return Number.isNaN(expireAt) ? 0 : expireAt;
  }

  private storeTokenExpiration(expiredAt: number) {
    localStorage.setItem(this.tokenExpireAtKey, expiredAt.toString());
  }
}
