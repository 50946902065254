import React from 'react';
import faq from 'src/angelology/assets/images/review-site/faq.png';
import {
  FAQTitle,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  AccordionWrapper,
  DropdownIcon,
  AccordionDetailsWrapper,
  Container,
  FAQWrapper,
  FAQContent,
  FAQList,
  FAQListItem,
  Paragraph,
  Bold,
  FAQNumericList,
  FAQNumericListItem,
  StyledFAQList,
  FAQImage,
} from './styled';

export type SectionFAQProps = {
  onGetReport: () => void;
};

const SectionFAQ = () => (
  <Container>
    <FAQWrapper>
      <FAQContent>
        <FAQTitle>FAQ - Frequently Asked Questions About Online Psychics</FAQTitle>
        <AccordionWrapper>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<DropdownIcon />}>
              What is a psychic reading?
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <AccordionDetailsWrapper>
                Contrary to popular belief, psychic reading doesn&apos;t have anything to do with
                ghosts or spirits or any other paranormal activities. While some of these intuitive
                counsellors do seem to have an inside line to otherworldly planes, most rely on what
                we call ESP or extrasensory perception. Armed with a heightened perceptive ability,
                these &quot;seers&quot; can tap into our energy and uncover secrets about our past,
                present, and future. We can then use these little details as a roadmap to improve
                different facets of our life, including love, relationship, career, money
                situations, and more.
              </AccordionDetailsWrapper>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<DropdownIcon />}>
              What are the different types of psychic reading?
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <AccordionDetailsWrapper>
                <Paragraph>
                  People seek the help of spiritual advisors for a variety of reasons. Hence,
                  you&apos;ll find different types of categories in online psychic reading sites.
                  Here are some of the most popular:
                </Paragraph>
                <FAQList>
                  <FAQListItem>- Tarot readings</FAQListItem>
                  <FAQListItem>- Fortune telling</FAQListItem>
                  <FAQListItem>- Dream analysis</FAQListItem>
                  <FAQListItem>- Career forecasts</FAQListItem>
                  <FAQListItem>- Love and relationship readings</FAQListItem>
                  <FAQListItem>- Oracle guidance</FAQListItem>
                  <FAQListItem>- Palm readings</FAQListItem>
                  <FAQListItem>- Astrology readings</FAQListItem>
                  <FAQListItem>- Numerology</FAQListItem>
                  <FAQListItem>- Kabbalah</FAQListItem>
                  <FAQListItem>- Sound baths</FAQListItem>
                  <FAQListItem>- Pet psychic</FAQListItem>
                  <FAQListItem>- Picture readings</FAQListItem>
                  <FAQListItem>- Angel insights</FAQListItem>
                  <FAQListItem>- Clairvoyance</FAQListItem>
                  <FAQListItem>- Chakra Healing</FAQListItem>
                  <FAQListItem>- Reiki, etc.</FAQListItem>
                </FAQList>
              </AccordionDetailsWrapper>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<DropdownIcon />}>
              How does an online psychic reading work? Is it better than in-person readings?
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <AccordionDetailsWrapper>
                <Paragraph>
                  When we say psychic reading, we probably picture a gypsy gazing into a crystal
                  ball in some dimly-lit carnival tent. Nowadays, however, psychic mediums,
                  clairvoyants, and other spiritual practitioners have gone online. Thanks to
                  technology, you can get a reading without meeting the psychic face to face. Many
                  online psychic communities have gifted advisors who can perform accurate readings
                  through phone, live chat, video calls, and emails.
                </Paragraph>
                <Paragraph>
                  An online psychic reading isn&apos;t that different from in-person readings. If
                  the psychic expert needs to read your palm or non-verbal cues, they would
                  recommend sitting for video reading. There are also obvious benefits like the
                  following:
                </Paragraph>
                <Paragraph>
                  <Bold>It&apos;s way cheaper</Bold>
                  {' '}
                  than face-to-face meetings, which can cost
                  upwards of $100 per hour. On the other hand, a session with an online psychic can
                  cost as little as $0.50 per minute. You can even get free minutes and discounts!
                </Paragraph>
                <Paragraph>
                  <Bold>It is more convenient.</Bold>
                  {' '}
                  Most online psychic reading networks have
                  mobile apps so you can get accurate readings without leaving your couch. No more
                  wasting time on long drives or waiting around for appointments!
                </Paragraph>
                <Paragraph>
                  <Bold>
                    You&apos;ll be spoilt for choice when it comes to psychic reading types.
                  </Bold>
                  {' '}
                  Lost a pet? On the verge of a divorce? Trying to meet your twin flame? These
                  online psychic portals have experts on those topics.
                </Paragraph>
                <Paragraph>
                  <Bold>Privacy and comfort.</Bold>
                  {' '}
                  Sharing your deepest secrets during a
                  face-to-face session can be difficult. An online psychic reading removes any
                  awkwardness or discomfort. If you don&apos;t want to show your face, you can
                  simply get a reading via chat or email.
                </Paragraph>
              </AccordionDetailsWrapper>
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<DropdownIcon />}>
              How do I prepare for a psychic reading? What questions can I ask my psychic advisor?
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <AccordionDetailsWrapper>
                <Paragraph>
                  Here are some tips on how to get the most out of your online psychic reading
                  session:
                </Paragraph>
                <FAQNumericList>
                  <FAQNumericListItem>
                    Decide on what you need some clarity on. Is it love? Money issues? Trouble at
                    home? This way, you can find the best psychics trained on those topics.
                  </FAQNumericListItem>
                  <FAQNumericListItem>
                    Decide on what you need some clarity on. Is it love? Money issues? Trouble at
                    home? This way, you can find the best psychics trained on those topics.
                  </FAQNumericListItem>
                  <FAQNumericListItem>
                    Decide on what you need some clarity on. Is it love? Money issues? Trouble at
                    home? This way, you can find the best psychics trained on those topics.
                  </FAQNumericListItem>
                  <FAQNumericListItem>
                    Decide on what you need some clarity on. Is it love? Money issues? Trouble at
                    home? This way, you can find the best psychics trained on those topics. Below
                    are some of the most popular questions that psychic readers get:
                  </FAQNumericListItem>
                </FAQNumericList>
                <StyledFAQList>
                  <FAQListItem>How can I find my soulmate?</FAQListItem>
                  <FAQListItem>Will I marry my current boyfriend or girlfriend?</FAQListItem>
                  <FAQListItem>Is my partner cheating?</FAQListItem>
                  <FAQListItem>What is the future of my current love relationship?</FAQListItem>
                  <FAQListItem>Will I get the promotion at work?</FAQListItem>
                  <FAQListItem>What does my future look like?</FAQListItem>
                  <FAQListItem>Have I chosen the right career path? Should I study abroad?</FAQListItem>
                  <FAQListItem>How do I know when it&apos;s time to quit my job?</FAQListItem>
                  <FAQListItem>Will I be successful in life?</FAQListItem>
                  <FAQListItem>Is there something my deceased loved one/s wants to tell me?</FAQListItem>
                  <FAQListItem>What does my future look like?</FAQListItem>
                </StyledFAQList>
              </AccordionDetailsWrapper>
            </StyledAccordionDetails>
          </StyledAccordion>
        </AccordionWrapper>
      </FAQContent>
      <FAQImage src={faq} />
    </FAQWrapper>
  </Container>
);

export default SectionFAQ;
