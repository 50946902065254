import React, { Suspense } from 'react';
// import styled from 'styled-components';
// import { BASE_ROUTES, LANDINGS } from 'src/angelology/constants/routes';
import ThemeContext from 'src/common/contexts/ThemeContext';
import MainContainer from 'src/angelology/components/MainContainer';
// import background from 'src/angelology/assets/images/quiz/bg_main.png';
import { ThemeTypes } from 'src/common/theme/types';
import ReviewSite from './pages/ReviewSite';
// import ContactUs from 'src/angelology/pages/ContactUs';
// import MoneyBackPolicy from 'src/angelology/pages/MoneyBackPolicy';
// import Terms from 'src/angelology/pages/Terms';
// import PrivacyPolicy from 'src/angelology/pages/PrivacyPolicy';
// import Faq from 'src/angelology/pages/Faq';
// import ThankYouPage from 'src/angelology/pages/ThankYouPage';
// import SubscriptionPolicy from 'src/angelology/pages/SubscriptionPolicy';

// const Container = styled.div`
//   position: relative;
//   min-height: 100%;
//   display: flex;
//   flex-direction: column;
//   background: url(${background}) no-repeat;
//   background-size: cover;
// `;

// const Angelology_19_99 = lazy(() => import('src/angelology/site/19.99'));
// const Angelology_7_99 = lazy(() => import('src/angelology/site/7.99'));
// const AngelologySubscription_7_99 = lazy(() => import('src/angelology/site/subscription_7.99'));

const App = () => (
  <Suspense fallback={<div />}>
    <ThemeContext themeType={ThemeTypes.ANGELOLOGY}>
      <MainContainer>
        <ReviewSite />
        {/* <Container>
          <MainContainer>
            <Route path={BASE_ROUTES.CONTACT_US} component={ContactUs} />
            <Route path={BASE_ROUTES.MONEY_BACK_POLICY} component={MoneyBackPolicy} />
            <Route path={BASE_ROUTES.TERMS} component={Terms} />
            <Route path={BASE_ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
            <Route path={BASE_ROUTES.FAQ} component={Faq} />
            <Route path={BASE_ROUTES.SUBSCRIPTION_POLICY} component={SubscriptionPolicy} />
            <Route path={LANDINGS.THANK_YOU_PAGE} component={ThankYouPage} />
            <Route path={BASE_ROUTES.ANGELOLOGY_19_99} component={Angelology_19_99} />
            <Route path={BASE_ROUTES.ANGELOLOGY_7_99} component={Angelology_7_99} />
            <Route path={BASE_ROUTES.ANGELOLOGY_SUBSCRIPTION_7_99} component={AngelologySubscription_7_99} />
          </MainContainer>
        </Container> */}
      </MainContainer>
    </ThemeContext>
  </Suspense>
);

export default App;
