import React, { forwardRef } from 'react';
import { useSpring, animated } from 'react-spring';

export type ModalFadeProps = {
  children?: React.ReactNode;
  in: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const ModalFade = forwardRef<HTMLDivElement, ModalFadeProps>((props, ref) => {
  const {
    in: open, children, onEnter, onExited, ...other
  } = props;
  const style = useSpring({
    from: {
      opacity: 0,
      outline: 'none',
    },
    to: {
      opacity: open ? 1 : 0,
      outline: 'none',
    },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export default ModalFade;
