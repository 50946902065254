import React from 'react';
import { Provider } from 'react-redux';
import { AngelologyServicesContext } from 'src/angelology/services/context';
import { useApp } from 'src/angelology/useApp';
import { ServicesContext } from 'src/common/services/context';

interface MainProps {
  children: React.ReactNode;
}

const MainContainer: React.FC<MainProps> = ({ children }) => {
  const {
    isAppLoaded, services, store, angelologyServices,
  } = useApp();

  if (!isAppLoaded || !services || !store || !angelologyServices) {
    return null;
  }

  return (
    <ServicesContext.Provider value={services}>
      <AngelologyServicesContext.Provider value={angelologyServices}>
        <Provider store={store}>{children}</Provider>
      </AngelologyServicesContext.Provider>
    </ServicesContext.Provider>
  );
};

export default MainContainer;
