/* eslint-disable */
import consola, { Consola } from 'consola';
import { LogExtra, Logger, LogLevels } from './Logger';
import { LoggerTransport } from './LoggerTransport';

export class ConsolaLogger implements Logger {
  private readonly logger: Consola;

  constructor(transports: Array<LoggerTransport> = []) {
    this.logger = consola;
    transports.forEach(transport => this.logger.addReporter(transport))
  }

  debug(message: string, extra?: LogExtra): void {
    this.logger.debug(message, extra);
  }

  error(message: string | Error, extra?: LogExtra): void {
    this.logger.error(message, extra);
  }

  info(message: string, extra?: LogExtra): void {
    this.logger.info(message, extra);
  }

  warn(message: string, extra?: LogExtra): void {
    this.logger.warn(message, extra);
  }

  log(level: LogLevels, message: string, extra?: LogExtra): void {
    switch (level) {
      case 'debug':
        return this.debug(message, extra);
      case 'info':
        return this.info(message, extra);
      case 'warn':
        return this.warn(message, extra);
      case 'error':
        return this.error(message, extra);
    }
    return this.debug(message, extra);
  }
}
