import {
  RefObject, useEffect, useRef, useState,
} from 'react';

export default function useScrolledToElement<Element extends HTMLElement>(offset = 0): [boolean, RefObject<Element>] {
  const [isScrolledToElement, setIsScrolledToElement] = useState(false);
  const currentElement = useRef<Element>(null);

  const onScroll = () => {
    if (!currentElement.current) {
      setIsScrolledToElement(false);
      return;
    }
    const { top } = currentElement.current.getBoundingClientRect();
    setIsScrolledToElement(top - offset <= window.innerHeight);
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  });

  return [isScrolledToElement, currentElement];
}
