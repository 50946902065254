import { createAction, createReducer } from '@reduxjs/toolkit';
import { AppThunk } from 'src/angelology/store/types/AppThunk';

export * from './selectors';

const initialState = {
  isPrivateDataCollectionAgreed: false,
};

const agreePrivateDataCollection = createAction('cookies/AGREE_PRIVATE_DATA_COLLECTION');

export const doAgreePrivateDataCollection = (): AppThunk => (dispatch, getState, context) => {
  const { cookiesService } = context;
  cookiesService.agreePrivateDataCollection();
  dispatch(agreePrivateDataCollection());
};

export const cookiesAgreementReducer = createReducer(initialState, (builder) => {
  builder.addCase(agreePrivateDataCollection, () => ({
    isPrivateDataCollectionAgreed: true,
  }));
});
