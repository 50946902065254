import React from 'react';
import Modal from 'src/common/components/Modal';
import SectionRating from './SectionRating';
import SectionHeader from './SectionHeader';
import SectionDescription from './SectionDescription';
import SectionGuide from './SectionGuide';
import SectionFAQ from './SectionFAQ';
import SectionDisclaimer from './SectionDisclaimer';
import SectionFooter from './SectionFooter';
import { Container } from './styled';
import { useReviewSite } from './useReviewSite';
import OfferBlock from './OfferBlock';
import ModalOffer from './ModalOffer';

const ReviewSite = () => {
  const {
    isScrolledToElement,
    currentElement,
    onApplicationOpen,
    openAskNebula,
    isModalVisible,
    setIsModalVisible,
    title,
  } = useReviewSite();

  return (
    <Container>
      <SectionHeader />
      <SectionRating onApplicationOpen={onApplicationOpen} />
      <SectionDescription ref={currentElement} />
      <SectionGuide />
      <SectionFAQ />
      <SectionDisclaimer />
      <SectionFooter />
      {isScrolledToElement && <OfferBlock openAskNebula={openAskNebula} />}
      <Modal open={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <ModalOffer title={title} openAskNebula={openAskNebula} onClose={() => setIsModalVisible(false)} />
      </Modal>
    </Container>
  );
};

export default ReviewSite;
