// TODO add real product name
export enum PurchaseProductName {
  PERSONALIZED_COMPATIBILITY_REPORT = 'personalized_compatibility_report',
  TAROT_SPREAD_FOR_2022 = 'tarot_spread_for_2022',
  NUMEROLOGY_CALCULATOR = 'numerology_calculator',
  ASTROMIX_WEEKLY_999 = 'astromix_weekly_9.99',
  ASTROMIX_WEEKLY_499 = 'astromix_weekly_4.99',
  ASTROMIX_7D_TRIAL_1W_2000 = 'astromix_7d_trial_1w_20.00',
  ASTROMIX_7D_TRIAL_1M_4999 = 'astromix_7d_trial_1m_49.99',
  ASTROMIX_7D_TRIAL_3M_9999 = 'astromix_7d_trial_3m_99.99',
  ASTROMIX_1W_999_1M_2856 = 'astromix_1w_9.99_1m_28.56',
  ASTROMIX_1M_1999_1M_2856 = 'astromix_1m_19.99_1m_28.56',
  ASTROMIX_1M_1499_1M_2856 = 'astromix_1m_14.99_1m_28.56',
  ASTROMIX_1M_2856 = 'astromix_1m_28.56',
  ASTROMIX_DISCOUNT_7D_TRIAL_3_MONTH_9999 = 'astromix_discount_7d_trial_3-month_99.99',
  ASTROMIX_FREE_TRIAL_WEEKLY_999 = 'astromix_free_trial_weekly_9.99',
  NUMEROLOGY_READING = 'numerology_reading',
  TAROT_SPREAD = 'tarot_spread',
  ARCHANGEL_RAPHAEL = 'archangel_raphael',
  ARCHANGEL_RAPHAEL_DISCOUNT = 'archangel_raphael_discount',
  ANGELOLOGY_WITH_SUBSCRIPTION_1_MONTH_999 = 'angelology_1m_9.99',
  ANGELOLOGY_WITH_SUBSCRIPTION_3_MONTHS_1999 = 'angelology_3m_19.99',
  ANGELOLOGY_WITH_SUBSCRIPTION_6_MONTHS_2999 = 'angelology_6m_29.99',
  ANGELOLOGY_WITH_SUBSCRIPTION_1_MONTH_DISCOUNT_799 = 'angelology_1m_discount_7.99',
  ANGELOLOGY_TAROT_SPREAD_FOR_2022 = 'angelology_tarot_spread_for_2022',
  ANGELOLOGY_NUMEROLOGY_CALCULATOR = 'angelology_numerology_calculator',
  ANGELOLOGY_PALMISTRY = 'angelology_palmistry',
  ARCHANGEL_RAPHAEL_799 = 'archangel_raphael_7.99',
  ARCHANGEL_RAPHAEL_DISCOUNT_499 = 'archangel_raphael_discount_4.99',
  ANGELOLOGY_TAROT_SPREAD_FOR_2022_599 = 'angelology_tarot_spread_for_2022_5.99',
  ANGELOLOGY_NUMEROLOGY_CALCULATOR_499 = 'angelology_numerology_calculator_4.99',
  ANGELOLOGY_PALMISTRY_499 = 'angelology_palmistry_4.99',
}
