import styled from 'styled-components';
import { ReactComponent as ReviewLogo } from 'src/angelology/assets/images/review-site/review-logo.svg';
import { ReactComponent as CompareIcon } from 'src/angelology/assets/images/review-site/compare.svg';
import { ReactComponent as QuestionIcon } from 'src/angelology/assets/images/review-site/questionMark.svg';
import { ReactComponent as ConnectIcon } from 'src/angelology/assets/images/review-site/connect.svg';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const NavBar = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.infoLight};
  padding: 20px 8px;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    justify-content: space-between;
    align-items: center;
    padding: 24px 40px;
    height: 34px;
  }
`;

export const StyledLink = styled(Link)`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: inline;
  }
`;

export const Logo = styled(ReviewLogo)`
  height: 24px;
  width: 193px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 34px;
    width: 263px;
  }
`;

export const HeaderBlock = styled.div`
  display: flex;
  justify-content: center;
  background: #dfcdf5;
  padding: 20px;
  border-radius: 12px;
  max-width: 1286px;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    justify-content: space-between;
    padding: 60px 70px 75px 60px;
  }
`;

export const DescriptionWrapper = styled.div`
  max-width: 304px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 600px;
  }
`;

export const Title = styled.h2`
  font-family: 'Poppins', 'sans-serif';
  font-weight: 700;
  font-size: 26px;
  line-height: 140%;
  margin-bottom: 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 44px;
    margin-bottom: 20px;
  }
`;

export const Subtitle = styled.p``;

export const Date = styled.span`
  color: #6a4dbc;
`;

export const TarotImage = styled.img`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: inline;
    width: 336px;
    height: 280px;
  }
`;

export const PreferencesWrapper = styled.div`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: flex;
    justify-content: space-between;
    max-width: 1048px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: -40px;
  }
`;

export const PreferencesItem = styled.div`
  display: flex;
  width: 296px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  background: #fbfdff;
  border: 1px solid #dfccf5;
  border-radius: 20px;
`;

export const PreferencesText = styled.p`
  width: 140px;
  font-family: 'Poppins', 'sans-serif';
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
`;

export const Compare = styled(CompareIcon)`
  margin-right: 34px;
`;

export const QuestionMark = styled(QuestionIcon)`
  margin-right: 25px;
`;

export const Connect = styled(ConnectIcon)`
  margin-right: 26px;
`;
