/* eslint-disable class-methods-use-this */
import qs from 'qs';
import { PurchaseProductName } from 'src/common/types';
import { AmplitudeService } from './AmplitudeService';
import { QUIZ_ANALYTIC_EVENTS } from './events';

export interface UtmTags {
  utm_source?: string,
  utm_medium?: string,
  utm_campaign?: string,
  utm_campaignid?: string,
  utm_adset?: string;
  utm_adsetid?: string;
  utm_ad?: string;
  utm_adid?: string;
  utm_content?: string,
  utm_term?: string,
  quizz?: string;
}

const POSSIBLE_UTM_TAGS: (keyof UtmTags)[] = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_campaignid',
  'utm_adset',
  'utm_adsetid',
  'utm_ad',
  'utm_adid',
  'utm_content',
  'utm_term',
  'quizz',
];

export class AnalyticsService {
  private amplitudeService: AmplitudeService;

  private readonly utmTags: UtmTags;

  private userId: string

  constructor(amplitudeService: AmplitudeService) {
    this.utmTags = this.loadUtmTags();
    this.saveUtmTagsToStorage(this.utmTags);

    this.userId = this.loadUserIdFromStorage();

    this.amplitudeService = amplitudeService;

    if (this.utmTags.utm_source) {
      this.amplitudeService.setUserProperties({
        media_source: this.utmTags.utm_source,
      });
    }
  }

  getUtmTags(): UtmTags {
    return this.utmTags;
  }

  trackQuizEvent(quizEvent: QUIZ_ANALYTIC_EVENTS, eventProperties: Record<string, any> = {}) {
    this.amplitudeService.trackQuizEvent(quizEvent, eventProperties);
  }

  setCurrentQuizName(quizName: string) {
    this.amplitudeService.setCurrentQuizName(quizName);
  }

  setUserId(userId: string) {
    this.amplitudeService.setUserId(userId);
    this.userId = userId;
  }

  launch() {
    this.amplitudeService.launch();
  }

  contactUsPageOpened() {
    this.amplitudeService.contactUsPageOpened();
  }

  policyPageOpened(pageName: string) {
    this.amplitudeService.policyPageOpened(pageName);
  }

  faqPageOpened() {
    this.amplitudeService.faqPageOpened();
  }

  preLandingLpOpened() {
    this.amplitudeService.preLandingLpOpened(this.userId);
  }

  acceptCookies() {
    this.amplitudeService.acceptCookies();
  }

  preLandingLpGetReport() {
    this.amplitudeService.preLandingLpGetReport(this.userId);
  }

  landingLpOpened() {
    this.amplitudeService.landingLpOpened(this.userId);
  }

  landingLpGetReport() {
    this.amplitudeService.landingLpGetReport(this.userId);
  }

  landingLpRevealMessageClick() {
    this.amplitudeService.landingLpRevealMessageClick(this.userId);
  }

  landingLpAdditionalDiscountPopup() {
    this.amplitudeService.landingLpAdditionalDiscountPopup(this.userId);
  }

  landingLpGetAdditionalDiscount() {
    this.amplitudeService.landingLpGetAdditionalDiscount(this.userId);
  }

  additionalDiscountLpOpened() {
    this.amplitudeService.additionalDiscountLpOpened(this.userId);
  }

  additionalDiscountLpGetReport(product: PurchaseProductName) {
    this.amplitudeService.additionalDiscountLpGetReport(this.userId, product);
  }

  reviewPageOpen() {
    this.amplitudeService.reviewPageOpen();
  }

  reviewPageClick(site: string) {
    this.amplitudeService.reviewPageClick(site);
  }

  reviewOfferOpen(offer: string) {
    this.amplitudeService.reviewOfferOpen(offer);
  }

  reviewOfferClick(offer: string) {
    this.amplitudeService.reviewOfferClick(offer);
  }

  numerologyLpOpened(pageOrder: number) {
    this.amplitudeService.numerologyLpOpened(this.userId, pageOrder);
  }

  numerologyLpGetReport(pageOrder: number) {
    this.amplitudeService.numerologyLpGetReport(this.userId, pageOrder);
  }

  numerologyLpSkip(pageOrder: number) {
    this.amplitudeService.numerologyLpSkip(this.userId, pageOrder);
  }

  tarotLpOpened(pageOrder: number) {
    this.amplitudeService.tarotLpOpened(this.userId, pageOrder);
  }

  tarotLpGetReport(pageOrder: number) {
    this.amplitudeService.tarotLpGetReport(pageOrder);
  }

  tarotLpSkip(pageOrder: number) {
    this.amplitudeService.tarotLpSkip(pageOrder);
  }

  palmistryLpOpened(pageOrder: number) {
    this.amplitudeService.palmistryLpOpened(this.userId, pageOrder);
  }

  palmistryLpGetReport(pageOrder: number) {
    this.amplitudeService.palmistryLpGetReport(pageOrder);
  }

  palmistryLpSkip(pageOrder: number) {
    this.amplitudeService.palmistryLpSkip(pageOrder);
  }

  thankYouPageOpened() {
    this.amplitudeService.thankYouPageOpened(this.userId);
  }

  paymentCardChosen() {
    this.amplitudeService.paymentCardChosen(this.userId);
  }

  paymentPaypalChosen() {
    this.amplitudeService.paymentPaypalChosen(this.userId);
  }

  paymentRetried() {
    this.amplitudeService.paymentRetried(this.userId);
  }

  paymentError() {
    this.amplitudeService.paymentError(this.userId);
  }

  paymentSuccess(paymentData: { productId: string, price: number; orderId: string }) {
    this.amplitudeService.paymentSuccess(paymentData);
  }

  private loadUtmTags() {
    const utmTags = this.loadUtmTagsFromStorage();

    const parsedQs = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    POSSIBLE_UTM_TAGS.forEach((utmTag) => {
      if (utmTag in parsedQs) {
        utmTags[utmTag] = this.extractUtmTagFromParsedQs(parsedQs[utmTag]);
      }
    });

    return utmTags;
  }

  private loadUtmTagsFromStorage(): UtmTags {
    try {
      const utmTagsRaw = window.sessionStorage.getItem('ASK_Nebula_utmTags') || '{}';
      return JSON.parse(utmTagsRaw);
    } catch (err) {
      // TODO(Logger)
      console.error('Failed to load utm tags', err);
      return {};
    }
  }

  private extractUtmTagFromParsedQs(parsedQs: unknown): string {
    return Array.isArray(parsedQs) ? parsedQs[0] : parsedQs;
  }

  private saveUtmTagsToStorage(utmTags: UtmTags) {
    try {
      window.sessionStorage.setItem('ASK_Nebula_utmTags', JSON.stringify(utmTags));
    } catch (err) {
      // TODO(Logger)
      console.error('Failed to save utm tags', err);
    }
  }

  private loadUserIdFromStorage(): string {
    try {
      const userStorage = sessionStorage.getItem('angelology') || '';
      return JSON.parse(userStorage).userProfile.id;
    } catch (err) {
      // TODO(Logger)
      console.error('Failed to load user id', err);
      return '';
    }
  }
}
