import styled from 'styled-components';

export const Container = styled.div`
  padding: 60px 15px 50px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 100px 0;
  }
`;

export const DisclaimerWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 330px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 800px;
  }
`;

export const Title = styled.h2`
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  padding-bottom: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 32px;
    padding-bottom: 24px;
    text-align: center;
  }
`;

export const Paragraph = styled.p`
  padding-bottom: 24px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    text-align: center;
  }
`;
