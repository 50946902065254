export enum PaymentOrderStatus {
  APPROVED = 'approved',
  REFUNDED = 'refunded',
  DECLINED = 'declined',
  PROCESSING = 'processing',
}

export enum PaymentType {
  CARD = 'card',
  PAYPAL = 'paypal'
}

export type PaymentOrderDescription = {
  status: PaymentOrderStatus;
  order_id: string;
  amount: number;
  currency: string;
  descriptor: string;
  fraudulent: boolean;
  processing_amount: number;
  processing_currency: number;
  refunded_amount: number;
};
