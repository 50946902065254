import { config } from 'src/angelology/config';
import { AmplitudeService, AnalyticsService } from './analytics';

export * from './analytics/AnalyticsService';

export interface AngelologyServices {
  analyticsService: AnalyticsService;
}

export const initAngelologyServices = async (): Promise<AngelologyServices> => {
  const amplitudeService = new AmplitudeService(config.amplitudeApiKey);
  const analyticsService = new AnalyticsService(amplitudeService);

  return {
    analyticsService,
  };
};
