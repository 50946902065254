import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 8px;
  padding-right: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 1286px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const DescriptionBlock = styled.div`
  background: #cdd3f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 344px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    flex-direction: row;
    padding: 60px;
    max-width: 100%;
    border-radius: 40px;
  }
`;

export const ImageMobile = styled.img`
  width: 344px;
  height: 120px;
  border-radius: 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const ImageDesktop = styled.img`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: inline-block;
    min-width: 382px;
    height: 100%;
    min-height: 527px;
    border-radius: 20px;
  }
`;

export const DescriptionTextBlock = styled.div`
  padding: 28px 20px 24px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 0 64px 0 0;
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  padding-bottom: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 32px;
    padding-bottom: 24px;
  }
`;

export const DescriptionText = styled.p`
  font-size: 14px;
  line-height: 140%;
  padding-bottom: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
    padding-bottom: 24px;
  }
`;

export const DescriptionTextList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  margin-bottom: -18px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: -24px;
  }
`;

export const DescriptionTextItem = styled.li`
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  padding-bottom: 18px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
    padding-bottom: 24px;
  }
`;
