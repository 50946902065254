import styled from 'styled-components';

export const Circle = styled.svg`
  transform: rotate(-90deg) scaleY(-1);
  width: 185px;
  height: 185px;
  margin: 0 auto;
  display: block;
  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 270px;
    height: 270px;
  }
`;
