import React from 'react';
import {
  Container, DisclaimerWrapper, Paragraph, Title,
} from './styled';

const SectionDisclaimer = () => (
  <Container>
    <DisclaimerWrapper>
      <Title>Disclaimer</Title>
      <Paragraph>
        Our website is free to use and was created with the aim of helping you - our reader - make
        an educated choice about the best psychic reading sites online. We receive advertising
        compensation from the psychic reading sites that are featured on this site, and this also
        has an effect on the placement and exposure these companies receive.
      </Paragraph>
      <Paragraph>
        The ratings assigned to companies are determined by our subjective opinion and based on our
        ranking methodology which takes into account brand reputation, performance, and general
        consumer interest. Psychic reading listings on this site DO NOT imply endorsement. We try to
        keep all information, including prices, as up-to-date as possible but this cannot always be
        assured and is subject to change without our knowledge.
      </Paragraph>
    </DisclaimerWrapper>
  </Container>
);

export default SectionDisclaimer;
