import amplitude from 'amplitude-js';
import { PurchaseProductName } from 'src/common/types';
import { QUIZ_ANALYTIC_EVENTS } from './events';

export class AmplitudeService {
  private instance: amplitude.AmplitudeClient;

  private userId: string

  constructor(apiKey: string) {
    this.instance = amplitude.getInstance();
    this.instance.init(apiKey);
    this.userId = '';
  }

  setUserProperties(userData: { [key: string]: unknown }) {
    this.instance.setUserProperties(userData);
  }

  launch() {
    if (!localStorage.getItem('launched')) {
      localStorage.setItem('launched', 'true');
      this.instance.logEvent('angelology_launch_first_time');
    } else if (!sessionStorage.getItem('launched')) {
      sessionStorage.setItem('launched', 'true');
      this.instance.logEvent('angelology_launch');
    }
  }

  trackQuizEvent(quizEvent: QUIZ_ANALYTIC_EVENTS, eventProperties: Record<string, any> = {}) {
    this.instance.logEvent(quizEvent, eventProperties);
  }

  setCurrentQuizName(quizName: string) {
    this.setUserProperties({
      flow: quizName,
    });
  }

  setUserId(userId: string) {
    this.instance.setUserId(userId);
    this.userId = userId;
  }

  acceptCookies() {
    this.instance.logEvent('flow_intro_accept_cookies');
  }

  faqPageOpened() {
    this.instance.logEvent('angels_faq_page_open');
  }

  contactUsPageOpened() {
    this.instance.logEvent('angels_contact_page_open');
  }

  policyPageOpened(pageName: string) {
    this.instance.logEvent('angels_policies_page_open', {
      page_name: pageName,
    });
  }

  paymentCardChosen(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.instance.logEvent('payment_card_chosen');
  }

  paymentPaypalChosen(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.instance.logEvent('payment_paypal_chosen');
  }

  reviewPageOpen() {
    this.instance.logEvent('review_page_open');
  }

  reviewPageClick(site: string) {
    this.instance.logEvent('review_page_click', {
      context: site,
    });
  }

  reviewOfferOpen(offer: string) {
    this.instance.logEvent('review_offer_open', {
      context: offer,
    });
  }

  reviewOfferClick(offer: string) {
    this.instance.logEvent('review_offer_click', {
      context: offer,
    });
  }

  paymentError(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.instance.logEvent('payment_error');
  }

  paymentRetried(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.instance.logEvent('payment_retry');
  }

  paymentSuccess({ productId }: { productId: string }) {
    this.setUserProperties({
      product_id: productId,
    });
    this.instance.logEvent('payment_success', {
      product_id: productId,
    });
  }

  preLandingLpOpened(userId: string) {
    this.setUserProperties({
      landing_page_id: 'pre-landing',
      user_id: userId,
    });
    this.instance.logEvent('lp_intro_open');
  }

  preLandingLpGetReport(userId: string) {
    this.setUserProperties({
      landing_page_id: 'pre-landing',
      user_id: userId,
    });
    this.instance.logEvent('lp_intro_getreport_click');
  }

  landingLpOpened(userId: string) {
    this.setUserProperties({
      landing_page_id: 'billing',
      user_id: userId,
    });
    this.instance.logEvent('lp_billing_open');
  }

  landingLpGetReport(userId: string) {
    this.setUserProperties({
      landing_page_id: 'billing',
      user_id: userId,
    });
    this.instance.logEvent('lp_billing_get_reading_click');
  }

  landingLpRevealMessageClick(userId: string) {
    this.setUserProperties({
      landing_page_id: 'billing',
      user_id: userId,
    });
    this.instance.logEvent('lp_billing_reveal_message_click');
  }

  landingLpAdditionalDiscountPopup(userId: string) {
    this.setUserProperties({
      landing_page_id: 'billing',
      user_id: userId,
    });
    this.instance.logEvent('lp_billing_extra_popup');
  }

  landingLpGetAdditionalDiscount(userId: string) {
    this.setUserProperties({
      landing_page_id: 'billing',
      user_id: userId,
    });
    this.instance.logEvent('lp_get_discount_click');
  }

  additionalDiscountLpOpened(userId: string) {
    this.setUserProperties({
      landing_page_id: 'additional-discount',
      user_id: userId,
    });
    this.instance.logEvent('lp_discount_open');
  }

  additionalDiscountLpGetReport(userId: string, productId: PurchaseProductName) {
    this.setUserProperties({
      landing_page_id: 'additional-discount',
      user_id: userId,
    });
    this.instance.logEvent('lp_discount_getreport_click', {
      product_id: productId,
    });
  }

  numerologyLpOpened(userId: string, pageOrder: number) {
    this.setUserProperties({
      landing_page_id: 'numerology',
      user_id: userId,
    });
    this.instance.logEvent('lp_upsell_screen_open', {
      page_num: pageOrder,
      product_id: PurchaseProductName.NUMEROLOGY_READING,
    });
  }

  numerologyLpGetReport(userId: string, pageOrder: number) {
    this.setUserProperties({
      landing_page_id: 'numerology',
      user_id: userId,
    });
    this.instance.logEvent('lp_upsell_get_report_click', {
      page_num: pageOrder,
      product_id: PurchaseProductName.NUMEROLOGY_READING,
    });
  }

  numerologyLpSkip(userId: string, pageOrder: number) {
    this.setUserProperties({
      landing_page_id: 'numerology',
      user_id: userId,
    });
    this.instance.logEvent('lp_upsell_skip_click', {
      page_num: pageOrder,
      product_id: PurchaseProductName.NUMEROLOGY_READING,
    });
  }

  tarotLpOpened(userId: string, pageOrder: number) {
    this.setUserProperties({
      landing_page_id: 'tarot',
      user_id: userId,
    });
    this.instance.logEvent('lp_upsell_screen_open', {
      page_num: pageOrder,
      product_id: PurchaseProductName.TAROT_SPREAD,
    });
  }

  tarotLpGetReport(pageOrder: number) {
    this.instance.logEvent('lp_upsell_get_report_click', {
      page_num: pageOrder,
      product_id: PurchaseProductName.TAROT_SPREAD,
    });
  }

  tarotLpSkip(pageOrder: number) {
    this.instance.logEvent('lp_upsell_skip_click', {
      page_num: pageOrder,
      product_id: PurchaseProductName.TAROT_SPREAD,
    });
  }

  palmistryLpOpened(userId: string, pageOrder: number) {
    this.setUserProperties({
      landing_page_id: 'tarot',
      user_id: userId,
    });
    this.instance.logEvent('lp_upsell_screen_open', {
      page_num: pageOrder,
      product_id: PurchaseProductName.ANGELOLOGY_PALMISTRY,
    });
  }

  palmistryLpGetReport(pageOrder: number) {
    this.instance.logEvent('lp_upsell_get_report_click', {
      page_num: pageOrder,
      product_id: PurchaseProductName.ANGELOLOGY_PALMISTRY,
    });
  }

  palmistryLpSkip(pageOrder: number) {
    this.instance.logEvent('lp_upsell_skip_click', {
      page_num: pageOrder,
      product_id: PurchaseProductName.ANGELOLOGY_PALMISTRY,
    });
  }

  thankYouPageOpened(userId: string) {
    this.setUserProperties({
      landing_page_id: 'thank-you',
      user_id: userId,
    });
    this.instance.logEvent('thankyou_report_open');
  }

  // emailMarketingLpOpened(userId: string, pageOrder: number) {
  //   this.setUserProperties({
  //     landing_page_id: `lp_abandoned_cart_${pageOrder}`,
  //     user_id: userId,
  //   });
  //   this.instance.logEvent('lp_abandoned_cart_open');
  // }
  //
  // emailMarketingLpGetProduct(userId: string, productName: PurchaseProductName, pageOrder: number) {
  //   this.setUserProperties({
  //     landing_page_id: `lp_abandoned_cart_${pageOrder}`,
  //     user_id: userId,
  //   });
  //   this.instance.logEvent('lp_abandoned_cart_click', {
  //     product_id: productName,
  //   });
  // }
}
