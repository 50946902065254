import { combineReducers } from 'redux';
import { userProfile } from './userProfile';
import { cookiesAgreementReducer } from './cookiesAgreement';

const rootReducer = combineReducers({
  userProfile,
  cookiesAgreement: cookiesAgreementReducer,
});

export type RootInitialState = ReturnType<typeof rootReducer>;

export default rootReducer;
