import React from 'react';
import { ReactComponent as ReviewLogo } from 'src/angelology/assets/images/review-site/review-logo.svg';
import {
  Container,
  CopyrightWrapper,
  FooterWrapper,
  LegalStaffItem,
  LegalStaffList,
  LegalStaffTitle,
  LegalStaffWrapper,
  CopyrightText,
} from './styled';

const SectionFooter = () => (
  <Container>
    <FooterWrapper>
      <LegalStaffWrapper>
        <LegalStaffTitle>Legal Stuff:</LegalStaffTitle>
        <LegalStaffList>
          <LegalStaffItem>Privacy Policy</LegalStaffItem>
          <LegalStaffItem>Terms of Use</LegalStaffItem>
          <LegalStaffItem>Disclaimer</LegalStaffItem>
        </LegalStaffList>
      </LegalStaffWrapper>
      <CopyrightWrapper>
        <ReviewLogo />
        <CopyrightText>
          Copyright © 2022 stars-chart.com. All Rights Reserved. The information we share on
          this website does not constitute legal or professional advice and should not be treated as
          such. Reproduction of this site in whole or in part is strictly prohibited.
        </CopyrightText>
      </CopyrightWrapper>
    </FooterWrapper>
  </Container>
);

export default SectionFooter;
