import { BaseService } from 'src/common/services/BaseService';
import HttpClient from 'src/common/api/HttpClient';
import { ApiError, UnknownError } from 'src/common/errors';
import { Locales } from 'src/common/location/locationOptions';
import { ConsolaLogger } from './logger';

export class LocationService extends BaseService {
  constructor(httpClient: HttpClient, logger: ConsolaLogger) {
    super(httpClient, logger);
  }

  async getIpLocation(): Promise<Locales> {
    try {
      const result = await this.httpClient.get('/user/locale');
      return result.data.locale;
    } catch (err) {
      const error = err instanceof ApiError ? err : new UnknownError(err);
      this.logger.error(error);
      return Locales.ESP;
    }
  }
}
