import styled from 'styled-components';
import ButtonComponent from 'src/angelology/components/Button';

export const Container = styled.div`
  background: #f6eeff;
  box-shadow: 0px 2px 6px rgba(28, 28, 62, 0.4);
  position: fixed;
  top: 0;
  width: 100%;
  height: 92px;
  padding-top: 12px;
  padding-bottom: 10px;
  padding: 12px 12px 10px 33px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    top: initial;
    bottom: 0;
    height: 120px;
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

export const OfferWrapper = styled.div`
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 1286px;
  }
`;

export const OfferContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BadgeMobileWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 78px;
  width: 24px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const BadgeDesktop = styled.img`
  display: none;
  height: 54px;
  width: 188px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: inline-block;
    flex-shrink: 1;
  }
`;

export const BadgeMobile = styled.img`
  position: absolute;
  height: 78px;
  width: 24px;
`;

export const BadgeText = styled.span`
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
`;

export const Logo = styled.img`
  width: 130px;
  height: 36px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 225px;
    height: 62px;
  }
`;

export const Button = styled(ButtonComponent)`
  background: #bb6bd9;
  width: 160px;
  height: 32px;
  margin: 0;
  cursor: pointer;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 220px;
    height: 44px;
  }
`;

export const Description = styled.p`
  font-size: 12px;
  line-height: 130%;
  color: #808080;
  font-family: 'Poppins', 'sans-serif';

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const DescriptionDesktop = styled(Description)`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: inline-block;
    font-size: 20px;
    max-width: 412px;
    font-weight: 600;
  }
`;

export const HightlightText = styled.span`
  color: #f2994a;
  text-transform: uppercase;
`;
