/* eslint-disable class-methods-use-this */
import { ConsolaReporterLogObject } from 'consola';
import {
  captureException,
  captureMessage,
  setContext,
  setTags,
} from '@sentry/react';
import { serialize } from 'src/common/utils/serialize';
import { LogExtra } from './Logger';
import { LoggerTransport, LoggerTransportLogObject } from './LoggerTransport';

export class SentryLoggerTransport implements LoggerTransport {
  log(
    logObj: LoggerTransportLogObject,
  ): void {
    const { args, type } = logObj;

    const message = args[0] as Error | string;
    const extraData = args[1] as LogExtra;

    if (extraData) {
      this.addContext(logObj);
    }

    if (type === 'error') {
      captureException(message);
      return;
    }

    captureMessage(
      typeof message === 'string'
        ? message
        : serialize(message),
    );
  }

  private addContext({ args }: ConsolaReporterLogObject) {
    const contextMessage = args[0];
    const contextData = args[1] as LogExtra;

    if (Array.isArray(contextData.tags) && contextData.tags.length) {
      setTags(contextData.tags);
    }

    if (contextMessage instanceof Error) {
      setContext(contextMessage.message, contextData);
    } else {
      setContext(
        typeof contextMessage === 'string'
          ? contextMessage
          : serialize(contextMessage), contextData,
      );
    }
  }
}
