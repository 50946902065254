import React, { forwardRef } from 'react';
import tarotMobile from 'src/angelology/assets/images/review-site/tarotMobile.png';
import tarotDesktop from 'src/angelology/assets/images/review-site/tarotDesktop.png';
import {
  Container,
  DescriptionBlock,
  DescriptionTextBlock,
  ImageMobile,
  ImageDesktop,
  Title,
  DescriptionText,
  DescriptionTextList,
  DescriptionTextItem,
} from './styled';

const SectionDescription = forwardRef<HTMLDivElement>(
  (_, ref) => (
    <Container ref={ref}>
      <DescriptionBlock>
        <ImageMobile src={tarotMobile} />
        <DescriptionTextBlock>
          <Title>Can tarot cards predict the future?</Title>
          <DescriptionText>
            Well, not in the way we&apos;ve seen in horror movies and TV shows. Despite all the
            stereotypes, tarot cards cannot summon spirits or predict future events. It is just one of
            the many divination tools psychics use to provide clarity and spiritual guidance.
            <br />
            <br />
            A deck of tarot cards consists of 78 cards — sorted into Major Arcana (22 cards) and
            Minor Arcana (56 cards) with four suits: King, Queen, Knight, and Jack. The former depicts
            significant life events and experiences, while the latter represent everyday actions and
            decisions. At the hands of a gifted psychic, tarot card reading can offer the following
            benefits:
          </DescriptionText>
          <DescriptionTextList>
            <DescriptionTextItem>
              It can offer profound insights into our past and present that can help clarify our
              thought processes and shape our future.
            </DescriptionTextItem>
            <DescriptionTextItem>
              If you&apos;re going through a rough patch, it can give you a sense of calm and peace.
              Knowing what&apos;s wrong in your present situation and what you can change will help
              you feel more in control of your life.
            </DescriptionTextItem>
            <DescriptionTextItem>
              At a crossroads in life? A tarot card reader can help guide you in the right direction.
            </DescriptionTextItem>
          </DescriptionTextList>
        </DescriptionTextBlock>
        <ImageDesktop src={tarotDesktop} />
      </DescriptionBlock>
    </Container>
  ),
);

export default SectionDescription;
