export enum BASE_ROUTES {
  ANGELOLOGY_19_99 = '/angelreport-onetime-19.99',
  ANGELOLOGY_7_99 = '/angelreport-onetime-7.99',
  ANGELOLOGY_SUBSCRIPTION_7_99 = '/angelreport-subscription-7.99',
  COOKIE_POLICY = '/cookie-policy',
  TERMS = '/terms',
  PRIVACY_POLICY = '/privacy-policy',
  SUBSCRIPTION_POLICY = '/subscription-policy',
  FAQ = '/faq',
  CONTACT_US = '/contact-us',
  MONEY_BACK_POLICY = '/money-back-policy',
  HOME = '/',
}

export enum LANDINGS {
  PRE_LANDING = '/lp/pre-landing',
  LANDING = '/lp/landing',
  ADDITIONAL_DISCOUNT = '/lp/additional-discount',
  EMAIL_MARKETING_1 = '/lp/email-marketing-1',
  EMAIL_MARKETING_2 = '/lp/email-marketing-2',
  EMAIL_MARKETING_3 = '/lp/email-marketing-3',
  NUMEROLOGY = '/lp/numerology',
  TAROT = '/lp/tarot',
  PALMISTRY = '/lp/palmistry',
  THANK_YOU_PAGE = '/lp/thank-you-page',
}

export enum REDIRECT_SITES {
  ASKNEBULA_QUIZ = 'https://asknebula.com/flow/lp/simple-registration',
  ORANUM = 'https://oranum.com/en/home',
  PSYCHICSOURSE = 'https://www.psychicsource.com/',
  KASAMBA = 'https://www.kasamba.com/',
  KEEN = 'https://www.keen.com/',
}
