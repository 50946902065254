import { Breakpoints } from 'src/common/types';
import { Indents } from './types';

export const darkTheme = {
  colors: {
    primary: '',
    secondary: '',
    primaryLight: '',
    secondaryLight: '',
    secondaryDark: '',
    success: '',
    error: '',
    warning: '',
    warningDark: '',
    info: '',
    infoLight: '',
    mainLink: '',
    light: '',
    inactive: '',
    active: '',
    primaryLink: '',
    dark: '',
  },
  gradients: {
    primary: '',
    primaryHover: '',
    lightActive: '',
  },
  indents: {
    xs: Indents.XS,
    sm: Indents.SM,
    md: Indents.MD,
    lg: Indents.LG,
    xl: Indents.XL,
  },
  breakpoints: {
    mobile: `(max-width: ${Breakpoints.MOBILE})`,
    tablet: `(min-width: ${Breakpoints.TABLET})`,
    desktop: `(min-width: ${Breakpoints.DESKTOP})`,
  },
};
