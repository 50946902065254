import styled from 'styled-components';
import Accordion, { AccordionSummary, AccordionDetails } from 'src/common/components/Accordion';
import Title from 'src/angelology/components/Title';

export const Container = styled.div`
  padding-left: 8px;
  padding-right: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-left: 40px;
    padding-right: 40px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1286px;
  }
`;

export const FAQWrapper = styled.div`
  background: #cdd3f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 344px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  color: #000;
  padding: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    flex-direction: row;
    max-width: 100%;
    border-radius: 40px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 60px;
  }
`;

export const FAQTitle = styled(Title)`
  margin: 0 auto;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-align: start;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-weight: 700;
    font-size: 32px;
  }
`;

export const FAQContent = styled.div``;

export const StyledAccordionSummary = styled(AccordionSummary)`
  font-size: 14px;
  font-weight: 600;
  flex-direction: row-reverse;

  & > .accordion-summary-content {
    margin: 0 !important;
    padding: 15px;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
    max-width: 566px;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 10px 15px 10px 40px !important;
  font-size: 12px;
  line-height: 130%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 20px 30px 20px 40px !important;
    font-size: 14px;
    line-height: 130%;
  }
`;

export const StyledAccordion = styled(Accordion)`
  margin: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  position: static !important;
  padding: 0;

  & > .accordion-summary-root {
    padding: 0;
  }
`;

export const AccordionWrapper = styled.div`
  width: 100%;
  max-width: 708px;
`;

export const DropdownIcon = styled.span`
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;

export const Link = styled.a`
  text-decoration: underline;
  font-weight: bold;
`;

export const AccordionDetailsWrapper = styled.p``;

export const FAQList = styled.ul``;

export const StyledFAQList = styled.ul`
  list-style: disc;
  list-style-position: inside;
`;

export const FAQNumericList = styled.ol`
  list-style: decimal;
  list-style-position: inside;
`;

export const FAQListItem = styled.li`
  font-size: 12px;
  line-height: 130%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const FAQNumericListItem = styled.li`
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 130%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const Paragraph = styled.p`
  padding-bottom: 24px;
`;

export const Bold = styled.b`
  font-weight: 600;
`;

export const FAQImage = styled.img`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: inline-block;
    width: 382px;
    height: 336px;
  }
`;
