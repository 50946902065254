import styled from 'styled-components';
import { ReactComponent as Close } from 'src/angelology/assets/images/cross.svg';
import ButtonComponent from 'src/angelology/components/Button';

export const Container = styled.div`
  background: #fff;
  border-radius: 16px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: flex;
    justify-content: space-between;
    width: 860px;
  }
`;

export const OfferImage = styled.img`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: inline-block;
    width: 287px;
    height: 100%;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

export const OfferWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 32px;
  flex-grow: 1;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-top: 28px;
    padding-bottom: 60px;
  }
`;

export const CloseIcon = styled(Close)`
  width: 16px;
  height: 16px;
  margin-left: 16px;
  cursor: pointer;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-left: 28px;
  }
`;

export const Title = styled.h2`
  background: #ccd3f5;
  margin-top: 12px;
  margin-bottom: 10px;
  font-family: 'Poppins', 'sans-serif';
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-weight: 500;
    font-size: 24px;
    width: fit-content;
    padding-left: 60px;
    padding-right: 30px;
    text-align: start;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
`;

export const Subtitle = styled(Title)`
  text-transform: uppercase;
  margin-bottom: 0;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-weight: 600;
    width: 497px;
    margin-bottom: 0;
  }
`;

export const Description = styled.ul`
  padding-left: 16px;
  padding-right: 16px;
  margin: 24px auto -8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 0;
    padding-left: 60px;
    margin-top: 32px;
    margin-bottom: -18px;
  }
`;

export const DescriptionItem = styled.li`
  display: flex;
  padding-bottom: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-bottom: 18px;
  }
`;

export const LogoWrapper = styled.div`
  min-width: 16px;
`;

export const DescriptionText = styled.span`
  font-size: 12px;
  line-height: 140%;
  margin-left: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 374px;
    font-size: 16px;
    white-space: nowrap;
  }
`;

export const DescriptionBoldText = styled(DescriptionText)`
  font-weight: 700;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 46px;
  }
`;

export const Button = styled(ButtonComponent)`
  background: #5c62de;
  width: 298px;
  height: 54px;
  margin: 0;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 437px;
  }
`;
