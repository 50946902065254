import React from 'react';
import badgeDesktop from 'src/angelology/assets/images/review-site/best_overall_desktop.png';
import badgeMobile from 'src/angelology/assets/images/review-site/best_overall_mobile.png';
import nebula from 'src/angelology/assets/images/review-site/nebula.png';
import {
  BadgeDesktop,
  BadgeMobile,
  BadgeMobileWrapper,
  BadgeText,
  Container,
  OfferWrapper,
  OfferContent,
  Description,
  DescriptionDesktop,
  Logo,
  Button,
  HightlightText,
} from './styled';

interface OfferBlockProps {
  openAskNebula: (path: string) => void;
}

const OfferBlock = ({ openAskNebula }: OfferBlockProps) => (
  <Container>
    <OfferWrapper>
      <BadgeMobileWrapper>
        <BadgeMobile src={badgeMobile} />
        <BadgeText>BEST</BadgeText>
      </BadgeMobileWrapper>
      <OfferContent>
        <BadgeDesktop src={badgeDesktop} />
        <Logo src={nebula} />
        <DescriptionDesktop>
          Limited Time Offer: Get
          <HightlightText> 100 Free credits </HightlightText>
          at the leading Psychic Reading Site!
        </DescriptionDesktop>
        <Button onClick={() => openAskNebula('banner')}>CLAIM OFFER</Button>
      </OfferContent>
      <Description>
        Limited Time Offer: Get
        <HightlightText> 100 Free credits </HightlightText>
        at the leading Psychic Reading Site!
      </Description>
    </OfferWrapper>
  </Container>
);

export default OfferBlock;
