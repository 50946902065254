import styled from 'styled-components';

export const Container = styled.div`
  padding: 60px 15px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 100px 0;
  }
`;

export const GuideWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 330px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 800px;
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  padding-bottom: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 32px;
    padding-bottom: 24px;
    text-align: center;
  }
`;

export const Subtitle = styled.h3`
  font-size: 14px;
  line-height: 140%;
  font-weight: 400;
  padding-bottom: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
    padding-bottom: 32px;
    text-align: center;
  }
`;

export const PointsList = styled.ul`
  margin-bottom: -20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: -24px;
  }
`;

export const PointsItem = styled.li`
  padding-bottom: 20px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-bottom: 24px;
  }
`;

export const PointTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
    margin-bottom: 12px;
  }

  ::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #dfccf5;
    border-radius: 50%;
    margin-right: 12px;
  }
`;

export const PointText = styled.p`
  font-size: 14px;
  line-height: 140%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 16px;
  }
`;
