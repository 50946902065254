import { createAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/angelology/store/types/AppThunk';
import { QUIZ_NAMES } from 'src/common/types';
import { UnknownError } from 'src/common/errors';
import { getUserInformation } from './selectors';

export * from './selectors';

export const setUserInformation = createAction(
  'user/USER_INFORMATION',
  (name: string, value: string | number | string[] | number[]) => ({
    payload: {
      name,
      value,
    },
  }),
);

export const userSignedUp = createAction<{ email: string; id: string }>('user/USER_SIGNED_UP');

export const signedUpByToken = createAction<{ id: string }>('user/SIGNED_UP_BY_TOKEN');

export const doSignUpViaQuiz = ({
  email,
  quizName,
}: {
  email: string;
  quizName: QUIZ_NAMES;
}): AppThunk => async (dispatch, getState, services) => {
  const { authService, analyticsService } = services;
  const userInformation = getUserInformation(getState());
  const utmTags = analyticsService.getUtmTags();

  const userData = {
    email,
    acquisition_source: quizName,
    acquisition_data: {
      ...userInformation,
    },
    media_source: utmTags.utm_source,
    quiz: utmTags.quizz,
    campaign_id: utmTags.utm_campaignid,
    campaign: utmTags.utm_campaign,
    adset_id: utmTags.utm_adsetid,
    adset: utmTags.utm_adset,
    ad_id: utmTags.utm_adid,
    ad: utmTags.utm_ad,
  };

  const authResult = await authService.signUpSoftUser(userData);
  dispatch(userSignedUp({ email, id: authResult.user.id }));
  analyticsService.setUserId(authResult.user.id);
};

export const doSignUpByToken = (token: string): AppThunk => async (dispatch, getState, services) => {
  const { authService, logger } = services;
  try {
    // TODO fill user profile
    const result = await authService.signInByToken(token);
    dispatch(signedUpByToken({ id: result.user.id }));
  } catch (err) {
    const error = err instanceof Error ? err : new UnknownError(err);
    logger.error(error);
  }
};
