import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { angelologyTheme, darkTheme, defaultTheme } from 'src/common/theme';
import { ThemeTypes } from 'src/common/theme/types';

export type ThemeProviderProps = {
  children?: React.ReactNode;
  themeType?: ThemeTypes;
};

const ThemeProvider = ({ children, themeType = ThemeTypes.DEFAULT }: ThemeProviderProps) => {
  const themes = {
    [ThemeTypes.DEFAULT]: defaultTheme,
    [ThemeTypes.ANGELOLOGY]: angelologyTheme,
    [ThemeTypes.DARK]: darkTheme,
  };
  return <StyledThemeProvider theme={themes[themeType]}>{children}</StyledThemeProvider>;
};
export default ThemeProvider;
