export enum ThemeTypes {
  DEFAULT = 'default',
  DARK = 'dark',
  ANGELOLOGY = 'angelology'
}

export enum Indents {
  XS = '10px',
  SM = '20px',
  MD = '30px',
  LG = '40px',
  XL = '50px'
}

export type ColorVariants = 'primary' | 'primaryLink' | 'secondary' | 'tertiary' | 'success';
