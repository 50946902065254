import styled from 'styled-components';
import { ReactComponent as ReviewLogo } from 'src/angelology/assets/images/review-site/review-logo.svg';

export const Container = styled.div`
  padding: 26px 20px 40px;
  background: #e7e7e7;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 60px 80px 150px;
  }
`;

export const FooterWrapper = styled.div`
  max-width: 330px;
  display: flex;
  flex-direction: column-reverse;
  margin-left: auto;
  margin-right: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    flex-direction: row;
    max-width: 1286px;
  }
`;

export const LegalStaffWrapper = styled.div`
  min-width: auto;
`;

export const LegalStaffList = styled.ul`
  display: flex;
  justify-content: space-between;

  @media ${(props) => props.theme.breakpoints.desktop} {
    flex-direction: column;
    justify-content: center;
    margin-bottom: -12px;
    min-width: 100px;
  }
`;

export const LegalStaffItem = styled.li`
  padding-bottom: 12px;
  font-size: 14px;
`;

export const LegalStaffTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  padding-bottom: 16px;
`;

export const CopyrightWrapper = styled.div`
  max-width: 760px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-left: 226px;
  }
`;

export const Logo = styled(ReviewLogo)`
  height: 34px;
  width: 263px;
`;

export const CopyrightText = styled.p`
  font-size: 14px;
  line-height: 140%;
  padding-top: 10px;
  padding-bottom: 20px;
`;
