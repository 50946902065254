import React from 'react';
import {
  ButtonIcon,
  ButtonLabel,
  ButtonLoadingIndicator,
  ButtonStyled,
} from './styled';
import { ButtonProps } from './types';

const Button = ({
  children,
  onClick,
  variant = 'primary',
  className,
  icon,
  loading,
  disabled,
  type = 'button',
}: ButtonProps) => {
  const renderIcon = () => {
    if (loading) {
      return (
        <ButtonIcon>
          <ButtonLoadingIndicator size={20} />
        </ButtonIcon>
      );
    }

    if (icon) {
      return (
        <ButtonIcon>{icon}</ButtonIcon>
      );
    }

    return null;
  };

  return (
    <ButtonStyled
      disabled={loading || disabled}
      className={className}
      type={type}
      onClick={onClick}
      variant={variant}
    >
      <ButtonLabel>
        {renderIcon()}
        {children}
      </ButtonLabel>
    </ButtonStyled>
  );
};

export default Button;
