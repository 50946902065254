import HttpClient from 'src/common/api/HttpClient';
import { Logger } from './logger';

export abstract class BaseService {
  protected httpClient: HttpClient;

  protected logger: Logger

  constructor(httpClient: HttpClient, logger: Logger) {
    this.httpClient = httpClient;
    this.logger = logger;
  }
}
