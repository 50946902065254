export enum QUIZ_NAMES {
  ASTROMIX = 'astromix',
  ANGELOLOGY = 'angelology'
}

export enum ANALYTICS_NAME {
  ONETIME_1999 = 'onetime_19.99',
  ONETIME_799 = 'onetime_7.99',
  SUBSCRIPTION_799 = 'subscription_7.99',
  WEEK_MONTH = '1w-1m',
  ASTROMIX_STANDARD = 'astromix_standard',
}

export interface BaseQuizStepData {
  id: string;
  screenType: string;
  content: Record<string, unknown>;
  stepOrder?: number;
  totalSteps?: number;
  canNotBackToScreen?: boolean;
}

export interface BaseQuizAnswerStepData extends BaseQuizStepData {
  dataKey: string;
  content: {
    title: string;
    answers: string[];
  };
}

export interface QuizData {
  name: QUIZ_NAMES;
  steps: Array<BaseQuizStepData>;
  baseUrl: string;
  nextStage: string;
}
