import React from 'react';
import {
  Container,
  GuideWrapper,
  Title,
  Subtitle,
  PointsList,
  PointsItem,
  PointTitle,
  PointText,
} from './styled';

const SectionGuide = () => (
  <Container>
    <GuideWrapper>
      <Title>The Beginner’s Guide to Online Psychic Readings</Title>
      <Subtitle>
        Before picking a psychic reading website, you should check a few things to get the most out
        of your psychic reading experience. Here are some important factors to consider:
      </Subtitle>
      <PointsList>
        <PointsItem>
          <PointTitle>Trial Offer</PointTitle>
          <PointText>
            Unlike the psychic in your nearest boho neighborhood, psychic reading websites often
            have trial offers to tempt you into signing up. This can be in the form of bonus credits
            or free minutes that you can use to find your best match. If you&apos;re not happy
            within the first few minutes, you can terminate the session without spending any money.
            Other than free minutes, sites like Oranum offers 24/7 live broadcasts so you know what
            to expect before paying for a session.
          </PointText>
        </PointsItem>
        <PointsItem>
          <PointTitle>Psychic vetting process</PointTitle>
          <PointText>
            All of the psychic reading sites featured on this page rigorously screen and vet their
            psychics to ensure authentic and legitimate readings.
          </PointText>
        </PointsItem>
        <PointsItem>
          <PointTitle>Satisfaction guarantee</PointTitle>
          <PointText>
            Many of the psychic reading sites we&apos;ve tested offer some sort of customer
            satisfaction guarantee. If, for any reason, you&apos;re not satisfied with the reading,
            you may receive credits, free minutes, or a refund. Kasamba, for example, offers a full
            or partial refund of up to $50.
          </PointText>
        </PointsItem>
        <PointsItem>
          <PointTitle>Customer feedback</PointTitle>
          <PointText>
            With hundreds of online psychics to choose from, you can choose which ones are truly
            gifted based on honest reviews from real people. Sites like Purple Garden and Kasamba
            are pretty transparent by making reviews public and displaying star ratings on each
            psychic&apos;s profile.
          </PointText>
        </PointsItem>
        <PointsItem>
          <PointTitle>Help via any device</PointTitle>
          <PointText>
            Our recommended psychic reading websites include a variety of options for you to
            connect. Top-rated psychics usually offer consultations via phone, live chat, or video
            call. If you&apos;re not keen on video calls or can&apos;t type fast enough, consider doing
            psychic phone readings. If you would instead rather consider your questions or even
            analyze the psychic&apos;s answers before submitting follow-up questions, an email psychic
            reading like those offered by Kasamba is your best bet.
          </PointText>
        </PointsItem>
      </PointsList>
    </GuideWrapper>
  </Container>
);

export default SectionGuide;
