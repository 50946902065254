export * from './Payment';
export * from './Zodiac';
export * from './Quiz';
export * from './Products';

export enum LoadingStatuses {
  IDLE = 1,
  PENDING = 2,
  FULFILLED,
  FAILED,
}

export enum Breakpoints {
  MOBILE = '580px',
  TABLET = '581px',
  DESKTOP = '1025px'
}

export type Timeout = ReturnType<typeof setTimeout>;
