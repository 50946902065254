export enum QUIZ_ANALYTIC_EVENTS {
  FLOW_INTRO_ACCEPT_COOKIES = 'flow_intro_accept_cookies',
  ANGELOLOGY_FAQ_PAGE_OPEN = 'angelology_faq_page_open',
  ANGELOLOGY_CONTACT_PAGE_OPEN = 'angelology_contact_page_open',
  ANGELOLOGY_POLICIES_PAGE_OPEN = 'angelology_policies_page_open',

  FLOW_START_OPEN = 'flow_start_open',
  FLOW_INFLUENCE_OPEN = 'flow_influence_open',
  FLOW_NAME_OPEN = 'flow_name_open',
  FLOW_GENDER_OPEN = 'flow_gender_open',
  FLOW_BIRTH_OPEN = 'flow_birth_open',
  FLOW_SPHERE_OPEN = 'flow_sphere_open',
  FLOW_MESSAGE_OPEN = 'flow_message_open',
  FLOW_PERSON_OPEN = 'flow_person_open',
  FLOW_NUMBERS_OPEN = 'flow_numbers_open',
  FLOW_PIMPLES_OPEN = 'flow_pimples_open',
  FLOW_CHILLS_OPEN = 'flow_chills_open',
  FLOW_SMELL_OPEN = 'flow_smell_open',
  FLOW_BRIGHSTAR_OPEN = 'flow_brighstar_open',
  FLOW_COLORS_OPEN = 'flow_color_open',
  FLOW_ELEMENT_OPEN = 'flow_element_open',
  FLOW_DREAM_OPEN = 'flow_dream_open',
  FLOW_ANGELMESSAGE_OPEN = 'flow_angelmessage_open',
  FLOW_LOADER_OPEN = 'flow_loader_open',
  FLOW_EMAIL_OPEN = 'flow_email_open',
  FLOW_LANDING_PAGE_REDIRECT = 'flow_landing_page_redirect',
}
