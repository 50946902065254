import React from 'react';
import { ReactComponent as Done } from 'src/angelology/assets/images/review-site/done.svg';
import nebula from 'src/angelology/assets/images/review-site/nebula.png';
import oranum from 'src/angelology/assets/images/review-site/oranum.png';
import oranumXl from 'src/angelology/assets/images/review-site/oranum-xl.png';
import psychicSource from 'src/angelology/assets/images/review-site/psychic-source.png';
import kasamba from 'src/angelology/assets/images/review-site/kasamba.png';
import kasambaXl from 'src/angelology/assets/images/review-site/kasamba-xl.png';
import keen from 'src/angelology/assets/images/review-site/keen.png';
import keenXl from 'src/angelology/assets/images/review-site/keen-xl.png';
import purpleGarden from 'src/angelology/assets/images/review-site/purple-garden.png';
import purpleGardenXl from 'src/angelology/assets/images/review-site/purple-garden-xl.png';
import mysticsense from 'src/angelology/assets/images/review-site/mysticsense.png';
import mysticsenseXl from 'src/angelology/assets/images/review-site/mysticsense-xl.png';
import topBadge from 'src/angelology/assets/images/review-site/top1.png';
import { REDIRECT_SITES } from 'src/angelology/constants';
import {
  Application,
  ApplicationHeader,
  ApplicationDescription,
  ApplicationPosition,
  Container,
  ApplicationDescriptionItem,
  ApplicationDescriptionText,
  LogoWrapper,
  ApplicationFooter,
  ProcessingWrapper,
  ProcessingLoader,
  LogoMobile,
  LogoDesktop,
  Estimate,
  Button,
  ApplicationWrapper,
  Nebula,
  NebulaHeader,
  NebulaLoader,
  NebulaEstimate,
  NebulaButton,
  TopBadge,
  NebulaDescriptionText,
} from './styled';

const applications = [
  {
    logoMobile: oranum,
    logoDesktop: oranumXl,
    preferences: [
      'Great introductory Rates',
      'Professional & Experieced: Established 10 Years Ago',
      'Get $9.99 in Free Credits after registration',
      'You can interact with an unlimited number of psychics without commitment',
    ],
    position: 2,
    value: 95,
    rating: 9.5,
    estimate: 'Great',
    path: REDIRECT_SITES.ORANUM,
    appName: 'oranum',
  },
  {
    logoMobile: psychicSource,
    logoDesktop: psychicSource,
    preferences: [
      'Offers a 100% money-back guarantee',
      'Offers a variety of readings for every budget',
      'The site has been around for over 30 years',
      'Looks a little old-school, but boasts a good selection of experienced psychics',
    ],
    position: 3,
    value: 92,
    rating: 9.2,
    estimate: 'Very good',
    path: REDIRECT_SITES.PSYCHICSOURSE,
    appName: 'psychicsource',
  },
  {
    logoMobile: kasamba,
    logoDesktop: kasambaXl,
    preferences: [
      '3 FREE Mins + 70% Off your First Session',
      'Offers a full money back guarantee',
      '$0.99 - $9.99 per minute',
      'Established over 20 years ago',
    ],
    position: 4,
    value: 91,
    rating: 9.1,
    estimate: 'Good',
    path: REDIRECT_SITES.KASAMBA,
    appName: 'kasamba',
  },
  {
    logoMobile: keen,
    logoDesktop: keenXl,
    preferences: [
      'Vast selection of advisors (over 1700 psychics to choose from)',
      'Offers 3 minutes free for all new users',
      'Read messages and stories from other users',
      "Explore psychic's profiles without having to create an account",
    ],
    position: 5,
    value: 88,
    rating: 8.8,
    estimate: 'Good',
    path: REDIRECT_SITES.KEEN,
    appName: 'keen',
  },
  {
    logoMobile: purpleGarden,
    logoDesktop: purpleGardenXl,
    preferences: [
      'Larg offering of quality psychics',
      'Live chat, phone, and face-to-face video sessions',
      'Read messages and stories from other users',
      'Clean and User friendly ',
    ],
    position: 6,
    value: 0,
    estimate: 'Not rated',
    path: '',
    appName: 'purplegarden',
  },
  {
    logoMobile: mysticsense,
    logoDesktop: mysticsenseXl,
    preferences: [
      'Well-designed and offers a pleasant user experience',
      'Filter psychics by specialty, tool, reading style, and availability',
      'Most psychics charge between $1 and $3 per minute',
      'New visitors get 5 minutes of free consultation after their first deposit',
    ],
    position: 7,
    value: 0,
    estimate: 'Not rated',
    path: '',
    appName: 'mysticsence',
  },
];

const nebulaPreferences = [
  'Over 100 high-quality psychics experts and clairvoyants',
  'Moneyback satisfaction guarantee',
  'Wide selection of Psychic readings',
];

interface SectionRatingProps {
  onApplicationOpen: (path: string, appName: string) => void;
}

const SectionRating = ({ onApplicationOpen }: SectionRatingProps) => (
  <Container>
    <ApplicationWrapper onClick={() => onApplicationOpen(REDIRECT_SITES.ASKNEBULA_QUIZ, 'nebula')}>
      <Nebula>
        <NebulaHeader>
          <ApplicationPosition>1</ApplicationPosition>
          <LogoMobile src={nebula} />
          <LogoDesktop src={nebula} />
          <TopBadge src={topBadge} />
        </NebulaHeader>
        <ApplicationDescription>
          {nebulaPreferences.map((item) => (
            <ApplicationDescriptionItem key={item}>
              <LogoWrapper>
                <Done />
              </LogoWrapper>
              <ApplicationDescriptionText>{item}</ApplicationDescriptionText>
            </ApplicationDescriptionItem>
          ))}
          <ApplicationDescriptionItem>
            <LogoWrapper>
              <Done />
            </LogoWrapper>
            <NebulaDescriptionText>Special offer: First 3 minutes FREE</NebulaDescriptionText>
          </ApplicationDescriptionItem>
        </ApplicationDescription>
        <ApplicationFooter>
          <ProcessingWrapper isRated>
            <NebulaLoader value={98} isRated />
            {9.8}
          </ProcessingWrapper>
          <NebulaEstimate isRated>Best Overall</NebulaEstimate>
          <NebulaButton isRated>Visit Website</NebulaButton>
        </ApplicationFooter>
      </Nebula>
    </ApplicationWrapper>
    {applications.map((application) => (
      <ApplicationWrapper
        onClick={() => onApplicationOpen(application.path, application.appName)}
        key={application.position}
      >
        <Application>
          <ApplicationHeader>
            <ApplicationPosition>{application.position}</ApplicationPosition>
            <LogoMobile src={application.logoMobile} />
            <LogoDesktop src={application.logoDesktop} />
          </ApplicationHeader>
          <ApplicationDescription>
            {application.preferences.map((item) => (
              <ApplicationDescriptionItem key={item}>
                <LogoWrapper>
                  <Done />
                </LogoWrapper>
                <ApplicationDescriptionText>{item}</ApplicationDescriptionText>
              </ApplicationDescriptionItem>
            ))}
          </ApplicationDescription>
          <ApplicationFooter>
            <ProcessingWrapper isRated={!!application.value}>
              <ProcessingLoader value={application.value} isRated={!!application.value} />
              {application.rating || '-'}
            </ProcessingWrapper>
            <Estimate isRated={!!application.value}>{application.estimate}</Estimate>
            <Button isRated={!!application.value}>Visit Website</Button>
          </ApplicationFooter>
        </Application>
      </ApplicationWrapper>
    ))}
  </Container>
);

export default SectionRating;
