import { createReducer } from '@reduxjs/toolkit';
import { setUserInformation, signedUpByToken, userSignedUp } from './index';

const initialState = {
  time: '',
  gender: '',
  name: '',
  email: '',
  id: '',
  colors: [] as string[],
  numbers2: [] as string[],
};

export const userProfile = createReducer(initialState, (builder) => {
  builder.addCase(setUserInformation, (state, action) => ({
    ...state,
    [action.payload.name]: action.payload.value,
  }))
    .addCase(userSignedUp, (state, action) => ({
      ...state,
      email: action.payload.email,
      id: action.payload.id,
    }))
    .addCase(signedUpByToken, (state, action) => ({
      ...state,
      id: action.payload.id,
    }));
});
