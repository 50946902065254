import React from 'react';
import { ReactComponent as Done } from 'src/angelology/assets/images/review-site/done.svg';
import expert from 'src/angelology/assets/images/review-site/expert.png';
import {
  Container,
  OfferWrapper,
  CloseIcon,
  Title,
  Subtitle,
  LogoWrapper,
  Description,
  DescriptionItem,
  DescriptionText,
  DescriptionBoldText,
  Button,
  ButtonWrapper,
  OfferImage,
} from './styled';

const nebulaPreferences = [
  'Largest offering of high-quality psychics and clairvoyants',
  'Exclusive live-chats with professional experts',
  'Very user friendly, Clean & intuitive interface',
];

interface ModalOfferProps {
  onClose: () => void;
  title: string;
  openAskNebula: (offer: string) => void;
}

const ModalOffer = ({ onClose, title, openAskNebula }: ModalOfferProps) => (
  <Container>
    <OfferWrapper>
      <CloseIcon onClick={onClose} />
      <Title>{title}</Title>
      <Subtitle>Nebula: Get 100 Free credits!</Subtitle>
      <Description>
        {nebulaPreferences.map((item) => (
          <DescriptionItem>
            <LogoWrapper>
              <Done />
            </LogoWrapper>
            <DescriptionText>{item}</DescriptionText>
          </DescriptionItem>
        ))}
        <DescriptionItem>
          <LogoWrapper>
            <Done />
          </LogoWrapper>
          <DescriptionBoldText>100 FREE credits for chats</DescriptionBoldText>
        </DescriptionItem>
      </Description>
      <ButtonWrapper>
        <Button onClick={() => openAskNebula('pop-up')}>
          GET 100 FREE CREDITS
        </Button>
      </ButtonWrapper>
    </OfferWrapper>
    <OfferImage src={expert} />
  </Container>
);

export default ModalOffer;
