import styled, { keyframes } from 'styled-components';
import ButtonComponent from 'src/angelology/components/Button';
import ProcessingLoaderCircle from 'src/angelology/components/Quiz/ProcessingLoader/ProcessingLoaderCircle';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 22px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-top: 74px;
  }
`;

export const ApplicationWrapper = styled.div`
  padding-bottom: 16px;
  width: -webkit-fill-available;
  cursor: pointer;
`;

export const Application = styled.div`
  border: 2px solid #d0f3ff;
  border-radius: 12px;
  width: 330px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 100%;
    max-width: 1048px;
    display: flex;
    justify-content: space-between;
    padding: 14px 40px 14px 20px;
  }

  :hover {
    transform: scale(1.02);
  }
`;

export const ApplicationHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 0 30px 0 20px;
  }
`;

export const ApplicationPosition = styled.span`
  font-weight: 600;
  font-size: 50px;
  line-height: 120%;
  min-width: 60px;
  color: #000;
  margin-right: -5px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-right: 0;
    font-weight: 700;
    font-size: 60px;
  }
`;

export const ApplicationDescription = styled.ul`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  margin-bottom: -8px;
  flex-grow: 1;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 0;
    padding-top: 0;
    padding-left: 30px;
  }
`;

export const ApplicationDescriptionItem = styled.li`
  display: flex;
  padding-bottom: 8px;
`;

export const LogoWrapper = styled.div`
  min-width: 16px;
`;

export const ApplicationDescriptionText = styled.span`
  font-size: 12px;
  line-height: 140%;
  margin-left: 8px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 374px;
    font-size: 16px;
  }
`;

export const ApplicationFooter = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-top: 19px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    flex-direction: column;
    padding-left: 0;
    padding-top: 0;
  }
`;

export const ProcessingWrapper = styled.div<{ isRated: boolean }>`
  position: relative;
  display: inline-block;
  width: 55px;
  line-height: 55px;
  text-align: center;
  color: ${(props) => (props.isRated ? '#000' : '#efefef')};

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 78px;
    line-height: 78px;
    font-weight: 700;
    font-size: 28px;
  }
`;

export const ProcessingLoader = styled(ProcessingLoaderCircle)<{ isRated: boolean }>`
  position: absolute;
  width: 56px;
  height: 56px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    min-width: 78px;
    min-height: 78px;
  }

  .donut-segment {
    stroke: ${(props) => (props.isRated ? '#fff' : '#ddd')};
  }
`;

export const LogoMobile = styled.img`
  width: 196px;
  height: 54px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const LogoDesktop = styled.img`
  display: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: inline-block;
    width: 261px;
    height: 72px;
  }
`;

export const Estimate = styled.span<{ isRated: boolean }>`
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  width: 53px;
  text-transform: uppercase;
  margin-left: 7px;
  margin-right: 7px;
  color: ${(props) => (props.isRated ? '#000' : '#efefef')};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 14px;
    margin-bottom: 16px;
    margin-top: 2px;
    width: auto;
  }
`;

export const Button = styled(ButtonComponent)<{ isRated: boolean }>`
  background: ${(props) => (props.isRated ? '#5c62de' : '#ddd')};
  width: 172px;
  height: 44px;
  margin: 0;
  cursor: ${(props) => (props.isRated ? 'pointer' : 'default !important')};
  z-index: 0;
`;

export const NebulaHeader = styled(ApplicationHeader)`
  background: #d0f3ff;
  justify-content: flex-start;
  padding-left: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-left: -20px;
    margin-top: -14px;
    margin-bottom: -14px;
    padding-left: 40px;
    padding-right: 30px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 12px;
  }
`;

export const NebulaLoader = styled(ProcessingLoader)`
  .donut-ring {
    stroke: #00e08f;
  }
`;

export const NebulaEstimate = styled(Estimate)`
  color: #06c580;
`;

export const Nebula = styled(Application)`
  position: relative;
  border-color: #f2c94c;
  border-top-right-radius: 0;
`;

export const TopBadge = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 46px;
  height: 74px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 82px;
    width: 51px;
  }
`;

const impulseBtn = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(39, 47, 201, 0.34), 0 0 0 0 rgba(39, 47, 201,.2)
  } 18% {
    box-shadow: 0 0 0 0 rgba(39, 47, 201,.3), 0 0 0 0 rgba(39, 47, 201,.2)
  } 100% {
    box-shadow:0 0 0 16px rgba(39, 47, 201,0),0 0 0 27px rgba(39, 47, 201,0)
  }
`;

export const NebulaButton = styled(Button)`
  animation-name: ${impulseBtn};
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  background: #272fc9;

  :hover {
    animation: none;
  }
`;

export const NebulaDescriptionText = styled(ApplicationDescriptionText)`
  font-weight: 700;
`;
