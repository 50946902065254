import { useEffect, useState } from 'react';
import { Store } from 'redux';
import { useLocation } from 'react-router-dom';
import { config } from 'src/angelology/config';
import { initServices, Services } from 'src/common/services';
import { RootInitialState } from 'src/angelology/store/rootReducer';
import createStore from 'src/angelology/store/store';
import { AngelologyServices, initAngelologyServices } from './services';

export const useApp = () => {
  const location = useLocation();
  const [appStore, setAppStore] = useState<Store<RootInitialState>>();
  const [angelologyServices, setAngelologyServices] = useState<AngelologyServices>();
  const [appServices, setAppServices] = useState<Services>();
  const [isAppLoaded, setIsAppLoaded] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    async function fetchServices() {
      const [analytics, services] = await Promise.all([
        initAngelologyServices(),
        initServices(config.apiUrl, config.apiPrefix),
      ]);
      setAngelologyServices(analytics);
      const store = createStore(services, analytics);
      setAppServices(services);
      setAppStore(store);
      setIsAppLoaded(true);
    }

    fetchServices();
  }, []);

  useEffect(() => {
    angelologyServices?.analyticsService.launch();
  }, [angelologyServices]);

  return {
    store: appStore,
    services: appServices,
    isAppLoaded,
    angelologyServices,
  };
};
