export enum ZodiacTypes {
  AQUARIUS = 'aquarius',
  ARIES = 'aries',
  CANCER = 'cancer',
  CAPRICORN = 'capricorn',
  GEMINI = 'gemini',
  LEO = 'leo',
  LIBRA = 'libra',
  PISCES = 'pisces',
  SAGITTARIUS = 'sagittarius',
  SCORPIO = 'scorpio',
  TAURUS = 'taurus',
  VIRGO = 'virgo',
}
