import HttpClient from 'src/common/api/HttpClient';
import { createContext } from 'react';
import { PurchaseService } from './PurchaseService';
import { ZodiacService } from './ZodiacService';
import { UserService } from './userService';
import { AuthService } from './AuthService';
import { CookiesService } from './CookiesService';
import { ConsolaLogger, Logger, SentryLoggerTransport } from './logger';
import { LocationService } from './LocationService';

export * from './PurchaseService';
export * from './CookiesService';
export * from './ZodiacService';
export * from './AuthService';
export * from './logger';

export interface Services {
  purchaseService: PurchaseService;
  zodiacService: ZodiacService;
  userService: UserService;
  authService: AuthService;
  cookiesService: CookiesService;
  locationService: LocationService;
  logger: Logger,
}

export const initServices = async (apiUrl: string, apiPrefix: string): Promise<Services> => {
  const httpClient = new HttpClient(apiUrl, apiPrefix);
  const logger = new ConsolaLogger([
    new SentryLoggerTransport(),
  ]);
  const cookiesService = new CookiesService();
  const purchaseService = new PurchaseService(httpClient, logger);
  const zodiacService = new ZodiacService(httpClient, logger);
  const userService = new UserService(httpClient, logger);
  const authService = new AuthService(httpClient, logger);
  const locationService = new LocationService(httpClient, logger);

  return {
    purchaseService,
    zodiacService,
    userService,
    authService,
    cookiesService,
    locationService,
    logger,
  };
};

export const ServicesContext = createContext<Services>(undefined!);
