import { useEffect, useRef, useState } from 'react';
import { Timeout } from 'src/common/types';
import useScrolledToElement from 'src/common/hooks/useScrolledToElement';
import { REDIRECT_SITES, SECOND } from 'src/angelology/constants';
import useAngelologyServices from 'src/angelology/hooks/useAngelologyServices';

export const useReviewSite = () => {
  const [isScrolledToElement, currentElement] = useScrolledToElement<HTMLDivElement>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [firstTimeTimerShown, setFirstTimeTimerShown] = useState(false);
  const showModal = useRef<Timeout>();

  const { analyticsService } = useAngelologyServices();

  const missOutTitle = "Don't miss out on this offer...";
  const notRatedTitle = 'Not rated. Check out Nebula instead';

  const [title, setTitle] = useState(missOutTitle);

  const firstTimeTimer = 15 * SECOND;
  const mainTimer = 30 * SECOND;

  const setShowModal = (time: number) => setTimeout(() => {
    setTitle(missOutTitle);
    setIsModalVisible(true);
    if (time === firstTimeTimer) {
      setFirstTimeTimerShown(true);
    }
  }, time);

  useEffect(() => {
    if (isModalVisible) {
      clearTimeout(showModal.current as Timeout);
      return;
    }

    if (firstTimeTimerShown) {
      showModal.current = setShowModal(firstTimeTimer);
      return;
    }

    showModal.current = setShowModal(mainTimer);
  }, [isModalVisible, firstTimeTimerShown]);

  const onApplicationOpen = (path: string, appName: string) => {
    analyticsService.reviewPageClick(appName);
    if (!path) {
      setTitle(notRatedTitle);
      setIsModalVisible(true);
      return;
    }

    window.location.assign(path);
  };

  const openAskNebula = (offer: string) => {
    analyticsService.reviewOfferClick(offer);
    window.location.assign(REDIRECT_SITES.ASKNEBULA_QUIZ);
  };

  useEffect(() => {
    analyticsService.reviewPageOpen();
  }, []);

  useEffect(() => {
    if (isScrolledToElement) {
      analyticsService.reviewOfferClick('banner');
    }
  }, [isScrolledToElement]);

  useEffect(() => {
    if (isModalVisible) {
      analyticsService.reviewOfferOpen('pop-up');
    }
  }, [isModalVisible]);

  return {
    isScrolledToElement,
    currentElement,
    onApplicationOpen,
    openAskNebula,
    isModalVisible,
    setIsModalVisible,
    title,
  };
};
