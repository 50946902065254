import { Breakpoints } from 'src/common/types';
import { Indents } from './types';

export const defaultTheme = {
  colors: {
    primary: '#333333',
    secondary: '#FBFBFF',
    primaryLight: '',
    secondaryLight: '#E0E0E0',
    secondaryDark: '#E0E0E0',
    success: '#27AE60',
    error: '#EB5757',
    warning: '#F2C94C',
    warningDark: '#F2994A',
    info: '#6A3AA2',
    infoLight: '#9974F6',
    inactive: '#828282',
    active: '#4F4F4F',
    primaryLink: '#219653',
    mainLink: '#0000EF',
    dark: '',
  },
  gradients: {
    primary: 'linear-gradient(165.54deg, #141333 -33.39%, #202261 15.89%, #543C97 55.84%, #6939A2 74.96%);',
    primaryHover: 'linear-gradient(167.62deg, #141333 -32.12%, #3639A2 4.19%, #6D4BCD 49.79%, #9D5BE9 81.72%);',
    lightActive: '',
  },
  indents: {
    xs: Indents.XS,
    sm: Indents.SM,
    md: Indents.MD,
    lg: Indents.LG,
    xl: Indents.XL,
  },
  breakpoints: {
    mobile: `(max-width: ${Breakpoints.MOBILE})`,
    tablet: `(min-width: ${Breakpoints.TABLET})`,
    desktop: `(min-width: ${Breakpoints.DESKTOP})`,
  },
};

export type Theme = typeof defaultTheme;
