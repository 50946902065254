/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ClassNameMap } from '@material-ui/styles';
import cn from 'classnames';
import MuiAccordionSummary, {
  AccordionSummaryProps,
  AccordionSummaryClassKey,
} from '@material-ui/core/AccordionSummary';

const summaryClasses: Partial<ClassNameMap<AccordionSummaryClassKey>> = {
  content: 'accordion-summary-content',
  root: 'accordion-summary-root',
};

export const AccordionSummary = (props: AccordionSummaryProps) => {
  const { classes: propsClasses } = props;

  const classes = {
    ...propsClasses,
    content: cn(propsClasses?.content, summaryClasses.content),
    root: cn(propsClasses?.content, summaryClasses.root),
  };

  return <MuiAccordionSummary {...props} classes={classes} />;
};
