import { PurchaseProductName } from 'src/common/types';
import { BaseService } from './BaseService';

export type PaymentDescriptionDTO = {
  formMerchantData: unknown;
  paypalPaymentUrl: string;
};

export type PurchaseDescriptionDTO = {
  cardPaymentUrl: string;
  paypalPaymentUrl: string
}

export interface ProductDTO {
  id: string
  name: string
  description: string | null
  product_type: string
  price: number
  created_at: Date
  updated_at: Date
}

export class PurchaseService extends BaseService {
  async createPayment(
    products: PurchaseProductName[],
    extraData?: Record<string, unknown>,
  ): Promise<PaymentDescriptionDTO> {
    const data = await this.httpClient.post('/payments', {
      products,
      extra_data: extraData,
    });

    return {
      formMerchantData: data.formMerchantData,
      paypalPaymentUrl: data.paypalPaymentData.scriptUrl,
    };
  }

  async purchaseReport(reportType: PurchaseProductName) {
    return this.httpClient.post('/reports/purchase', {
      product_name: reportType,
    });
  }

  async getProduct(productName: PurchaseProductName): Promise<ProductDTO> {
    return this.httpClient.get(`/products/${productName}`);
  }
}
