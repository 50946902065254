export enum Locales {
  OTHER = 'OTHER',
  US = 'US',
  UK = 'UK',
  ESP = 'ESP',
  UA = 'UA',
  RU = 'RU',
}

interface LocalesType {
  [key: string]: Locales;
}

export const locales: LocalesType = {
  OTHER: Locales.OTHER,
  US: Locales.US,
  UK: Locales.UK,
  ESP: Locales.ESP,
  UA: Locales.UA,
  RU: Locales.RU,
};
