import React from 'react';
import { CircularProgress as MaterialCircularProgress, makeStyles } from '@material-ui/core';

export type CircularProgressProps = {
  size?: number | string
  color?: string
  className?:string
}

const CircularProgress = (props: CircularProgressProps) => {
  const {
    size,
    color,
    className,
  } = props;

  const useStyles = makeStyles({
    root: {
      color,
    },
  });

  const classes = useStyles();

  return (
    <MaterialCircularProgress
      className={className}
      classes={{
        root: classes.root,
      }}
      size={size}
    />
  );
};

export default CircularProgress;
