import { applyMiddleware, createStore as createReduxStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { CookiesService, Services } from 'src/common/services';
import { AngelologyServices } from 'src/angelology/services';
import rootReducer, { RootInitialState } from './rootReducer';
import { saveProgressToStorage, loadProgressFromStorage } from './storage';

function loadStoreData(cookiesService: CookiesService): Partial<RootInitialState> {
  const persistedProgress = loadProgressFromStorage();
  const cookiesAgreementState = {
    isPrivateDataCollectionAgreed: cookiesService.isPrivateDataCollectionAgreed(),
  };

  return {
    ...persistedProgress,
    cookiesAgreement: cookiesAgreementState,
  };
}

export default function createStore(services: Services, angelologyServices: AngelologyServices) {
  const storeData = loadStoreData(services.cookiesService);
  const thunkWithContext = thunk.withExtraArgument({ ...services, ...angelologyServices });

  const store = createReduxStore(
    rootReducer,
    storeData,
    composeWithDevTools(applyMiddleware(thunkWithContext)),
  );

  store.subscribe(() => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      cookiesAgreement,
      ...progressState
    } = store.getState();

    saveProgressToStorage(progressState);
  });

  return store;
}
