export interface Config {
  geocodeUrl: string;
  geocodeApiKey: string;
  apiUrl: string;
  apiPrefix: string;
  mainAppUrl: string;
  amplitudeApiKey: string;
  dateOfTerms: string;
  mail: string;
}

const stageConfig: Config = {
  geocodeUrl: 'https://api.geocode.earth/v1/autocomplete',
  geocodeApiKey: 'ge-167dccfa75019f47',
  apiUrl: 'https://stage-api-asknebula.asknebula.com/api/',
  apiPrefix: 'v1',
  mainAppUrl: 'https://asknebula.com/',
  amplitudeApiKey: 'b65e7111ad2e5127419c9ae0d513ba38',
  dateOfTerms: '09 August 2021',
  mail: 'hello@stars-chart.com',
};

const productionConfig: Config = {
  geocodeUrl: 'https://api.geocode.earth/v1/autocomplete',
  geocodeApiKey: 'ge-167dccfa75019f47',
  apiUrl: 'https://api.stars-chart.com/api/',
  apiPrefix: 'v1',
  mainAppUrl: 'https://asknebula.com/',
  amplitudeApiKey: '161e3a3819d03611636352e8a4b5e969',
  dateOfTerms: '09 August 2021',
  mail: 'hello@stars-chart.com',
};

export const config = process.env.REACT_APP_ENV === 'production' ? productionConfig : stageConfig;
