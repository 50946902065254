import React from 'react';
import tarot from 'src/angelology/assets/images/review-site/tarot.png';
import { BASE_ROUTES } from 'src/angelology/constants';
import {
  Container,
  NavBar,
  Logo,
  StyledLink,
  HeaderBlock,
  DescriptionWrapper,
  Title,
  Subtitle,
  Date,
  TarotImage,
  PreferencesWrapper,
  PreferencesItem,
  PreferencesText,
  Compare,
  QuestionMark,
  Connect,
} from './styled';

const SectionHeader = () => (
  <Container>
    <NavBar>
      <Logo />
      <StyledLink to={BASE_ROUTES.HOME}>Home</StyledLink>
    </NavBar>
    <HeaderBlock>
      <DescriptionWrapper>
        <Title>
          Top psychic sites for tarot readings -
          <Date>August 2022</Date>
        </Title>
        <Subtitle>
          On these online platform we test, review, and compare the leading psychic reading sites.
          Get advice on any device via call, text chat, email, or video. An online psychic reading
          can offer clarity and guidance when you need it most.
        </Subtitle>
      </DescriptionWrapper>
      <TarotImage src={tarot} />
    </HeaderBlock>
    <PreferencesWrapper>
      <PreferencesItem>
        <Compare />
        <PreferencesText>Compare top sites</PreferencesText>
      </PreferencesItem>
      <PreferencesItem>
        <Connect />
        <PreferencesText>Connect via chat/call</PreferencesText>
      </PreferencesItem>
      <PreferencesItem>
        <QuestionMark />
        <PreferencesText>Get answers & Guidance</PreferencesText>
      </PreferencesItem>
    </PreferencesWrapper>
  </Container>
);

export default SectionHeader;
